import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import keycloak from '../../keycloak';
import { ConfirmModal } from '../Modal';
import PageTitle from '../PageTitle';
import Mick from './Mick';

export const BaseLayout = styled(({ className }: ClassNameProps) => {
  useEffect(() => {
    //Token Refresh
    const interval = setInterval(() => {
      keycloak
        .updateToken(70)

        .catch(() => {
          console.error('Failed to refresh token');
        });
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // const tour = usePageTour();

  return (
    <Container fluid className={`${className} base-layout`}>
      <PageTitle />
      <Outlet />
      <ConfirmModal />
      <Mick />
      {/* <Joyride
        {...tour}
        styles={{
          options: {
            zIndex: 10000
          }
        }}
      /> */}
    </Container>
  );
})`
  &.base-layout {
    height: 100%;
    padding: 20px;
    @media (max-width: 767.98px) {
      padding: 0;
    }
  }
`;
