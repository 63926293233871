import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useId, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput, { DefaultInputComponentProps } from 'react-phone-number-input';
import Select, { Props, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import TimeField from 'react-simple-timefield';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import Button from '../Button';
import Icon from '../Icon';
dayjs.extend(utc);
dayjs.extend(timezone);

const FormGroup = styled(({ className, controlId, children }: { controlId?: string } & ClassNameProps) => {
  return (
    <Form.Group controlId={controlId} className={`${className} form-group`}>
      {children}
    </Form.Group>
  );
})`
  margin: var(--form-group-margin);
`;

// dummy input to prevent native autocomplete
const Input = (props: any) => {
  return <components.Input {...props} autoComplete="one-time-code" />;
};

const Option = styled(({ children, className, isSelected, innerProps }) => {
  return (
    <div
      className={cx('form-select__option', className, {
        'form-select__option_selected': isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      {children}
    </div>
  );
})`
  padding: 6px 3px;
  &:hover {
    background-color: var(--gray);
  }
  &.form-select__option_selected {
    background-color: var(--azure);
  }
`;

type InnerItemProps = {
  children: React.ReactNode;
};

const InnerItem = React.memo(({ children }: InnerItemProps) => {
  return <>{children}</>;
});

const getListHeight = (length) => {
  return length < 6 ? length * 40 : 6 * 40;
};

const MenuList = ({ options, children, getValue, ...rest }) => {
  const virtuosoRef: any = useRef(null);
  const [option] = getValue();

  useEffect(() => {
    if (virtuosoRef?.current) {
      let selectedOptionIndex = 0;

      if (option) {
        selectedOptionIndex = options.findIndex((item) => item.value === option.value);
      }

      virtuosoRef.current.scrollToIndex({
        index: selectedOptionIndex,
        align: 'start',
        behavior: 'auto'
      });
    }
  }, [children, virtuosoRef, options, option]);

  return Array.isArray(children) ? (
    <Virtuoso
      ref={virtuosoRef}
      overscan={{ main: 12, reverse: 12 }}
      style={{ height: `${getListHeight(children.length)}px` }}
      totalCount={children.length}
      itemContent={(index) => <InnerItem children={children[index]} />}
    />
  ) : (
    <div>{children}</div>
  );
};

const SelectStyles = {
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: '12px',
    fontStyle: 'italic',
    color: 'var(--input-placeholder-color)',
    textWrap: 'nowrap'
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    color: 'inherit'
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    fontSize: '12px'
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    lineHeight: '12px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '12px'
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    fontSize: '12px'
  })
};

export const BasicSelect = styled(
  forwardRef<any>((props, ref) => {
    return <Form.Select ref={ref} {...props} />;
  })
)`
  &.inline {
    display: inline-block;
  }
  &:focus {
    box-shadow: var(--input-box-shadow);
  }
`;

const FormSelect = styled(
  forwardRef<any>(
    (
      {
        className,
        isLoading,
        loadOptions,
        isInvalid,
        normalizeChange, // set true when using with react hook form
        portalTarget, // element to which the menu should be attached (defaults to body)
        ...rest
      }: {
        isLoading?: boolean;
        loadOptions?: any;
        portalTarget?: any;
        isInvalid?: boolean;
        normalizeChange?: boolean;
      } & ClassNameProps &
        Props,
      ref
    ) => {
      const _id = useId();
      return loadOptions ? (
        <AsyncSelect
          ref={ref}
          key={`${_id}_${rest.value}`}
          classNamePrefix="form-select"
          styles={SelectStyles}
          loadOptions={loadOptions}
          components={{ Input }}
          isLoading={isLoading}
          menuPortalTarget={portalTarget}
          {...rest}
          className={`${className} ${isInvalid ? 'is-invalid' : ''} form-control form-select-input`}
        />
      ) : (
        <Select
          ref={ref}
          key={`${_id}_${rest.value}`}
          classNamePrefix="form-select"
          styles={SelectStyles}
          menuPortalTarget={portalTarget}
          components={{ Input, MenuList, Option }}
          isLoading={isLoading}
          {...rest}
          className={`${className} ${isInvalid ? 'is-invalid' : ''}  form-control form-select-input`}
        />
      );
    }
  )
)`
  &.form-control {
    border: 0;
    padding: 0;
    &.is-invalid {
      background-color: #ffdfdf;
      .form-select__control {
        background-color: #ffdfdf;
        border: 1px solid var(--danger);
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }
    }
    .form-select__control--is-focused {
      box-shadow: var(--input-box-shadow) !important;
      border-color: #86b7fe !important;
      border-width: 1px !important;
      outline: none;
    }
  }
  & > div[class$='-control'],
  &.select-loading {
    height: auto;
    min-height: var(--input-height);
    padding: 0;
    &:focus {
      box-shadow: var(--input-box-shadow);
    }
  }
`;

const FormControl = styled(
  forwardRef(({ className, type, ...rest }: { type?: string } & ClassNameProps, ref: any) => {
    return <Form.Control {...rest} ref={ref} type={type} className={`${className}  form-control--${type}`} />;
  })
)`
  height: var(--input-height);
  padding: var(--input-padding);
  &.is-invalid {
    background-color: #ffdfdf;
  }
  &:focus {
    box-shadow: var(--input-box-shadow);
  }
  &::placeholder {
    color: var(--input-placeholder-color);
    font-style: italic;
  }
`;

export const SemiRichEditor = styled(
  forwardRef<any, HTMLTextAreaElement>(
    ({ className, value, variableOptions, onBlur, errorMsg, readOnly, onChange, ...rest }: any, ref: any) => {
      const [variable, setVariable] = useState<string>(variableOptions.length > 0 ? variableOptions[0].value : '');

      useEffect(() => {
        if (variableOptions.length > 0 && (isNil(variable) || variable === '')) {
          setVariable(variableOptions[0].value);
        }
      }, [variableOptions, variable, setVariable]);

      const inputRef = useRef<HTMLTextAreaElement>();

      const onInsert = useCallback(() => {
        const content = inputRef.current!.value;
        const selectionStart = inputRef.current!.selectionStart;
        const selectionEnd = inputRef.current!.selectionEnd;
        const endOfContent = content.substring(selectionEnd, content.length);

        inputRef.current!.value = `${content.substring(0, selectionStart)}${variable}${endOfContent}`;
        inputRef.current!.focus();
        inputRef.current!.setSelectionRange(selectionEnd + variable.length, selectionEnd + variable.length);

        if (!isNil(onChange)) {
          onChange({ target: inputRef.current });
        }
      }, [ref, variable, onChange]);

      return (
        <div className={className}>
          {!readOnly && (
            <div className="sre__tools mb-1">
              <BasicSelect
                disabled={variableOptions.length < 1}
                onChange={(o: any) => {
                  setVariable(o.target.value);
                }}
              >
                {variableOptions.map((o: any) => {
                  return (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  );
                })}
              </BasicSelect>
              <Button variant="primary" onClick={onInsert} disabled={variableOptions.length < 1}>
                Insert Variable
              </Button>
            </div>
          )}
          <FormControl
            readOnly={readOnly}
            as="textarea"
            rows={5}
            onChange={onChange}
            className={`${!isNil(errorMsg) ? 'is-invalid' : ''}`}
            {...rest}
            ref={(r) => {
              inputRef.current = r;
              if (!isNil(ref)) {
                ref(r);
              }
            }}
          />
          <Form.Control.Feedback type="invalid">
            <>{errorMsg}</>
          </Form.Control.Feedback>
        </div>
      );
    }
  )
)`
  border: 1px solid var(--border-color);
  background-color: var(--border-color);
  border-radius: var(--border-radius-xs);
  padding: 8px;
  .sre__tools {
    border-radius: var(--border-radius-xs);
    .form-label {
      margin-right: 8px;
    }
    select {
      display: inline-block;
      width: auto;
      margin-right: 8px;
      min-width: 100px;
    }
  }
`;

const FormDateInput = styled(({ className, ...rest }: ClassNameProps & DatePickerProps) => {
  return (
    <DatePicker
      {...rest}
      dateFormat="MM/dd/yyyy"
      customInput={<FormControl type="text" />}
      className={`${className}`}
    />
  );
})``;

interface IFormTimeInput {
  value: string;
  onChange: any;
}

const FormTimeInput = styled(({ className, value, onChange }: ClassNameProps & IFormTimeInput) => {
  return (
    <div className={`${className}`}>
      <div className={`timeentry`}>
        <TimeField
          key="time-selection"
          value={value}
          onChange={(e) => {
            e.stopPropagation();
            onChange(e.target.value);
          }}
        />
        <div className={`timezone`}>CT</div>
      </div>
    </div>
  );
})`
  .timeentry {
    display: flex;
  }
  .timezone {
    padding: 4px;
  }
`;

interface IFormDateInputWithTime {
  value: string;
  onChange: any;
  isInvalid: boolean;
  errorMsg?: string;
}

const FormDateInputWithTime = styled(
  ({
    className,
    selected,
    isInvalid,
    errorMsg,
    ...rest
  }: ClassNameProps & IFormDateInputWithTime & DatePickerProps) => {
    return (
      <div className={`${className} ${isInvalid ? 'is-invalid' : ''}`}>
        <DatePicker
          {...rest}
          key="date-selection"
          dateFormat="MM/dd/yyyy HH:mm"
          customInput={
            <FormControl
              type="text"
              className={`${className} ${isInvalid ? 'is-invalid' : ''}  form-control--text form-control`}
            />
          }
          className={`${className}`}
          autoFocus={false}
          selected={selected}
          showTimeInput
          shouldCloseOnSelect={false}
          customTimeInput={<FormTimeInput key="time-selection" value={selected} />}
        />
        <Form.Control.Feedback type="invalid">
          <>{errorMsg}</>
        </Form.Control.Feedback>
      </div>
    );
  }
)`
  .invalid-feedback {
    display: block;
  }
`;

const FormCheck = styled(
  forwardRef<any, any>((rest, ref) => {
    return <Form.Check ref={ref} {...rest} />;
  })
)`
  line-height: 24px;
  input {
    width: 20px;
    height: 20px;
    line-height: 12px;
    margin-right: 6px;
  }
`;

const FormText = styled(({ className, ...rest }: ClassNameProps) => {
  return <Form.Text {...rest} className={`${className}`} />;
})``;
const FormLabel = styled(({ className, ...rest }: ClassNameProps) => {
  return <Form.Label {...rest} className={`${className}`} />;
})`
  letter-spacing: 0.4px;
  margin: var(--input-label-margin);

  &.inline {
    display: inline-block;
    padding-right: 8px;
  }
`;
const FormTextInput = styled(({ className, ...rest }: ClassNameProps) => {
  return <Form.Control type="text" {...rest} className={`${className}`} />;
})``;

const FormInfo = styled(({ className, children, ...rest }: ClassNameProps) => {
  return (
    <span {...rest} className={`${className}`}>
      {children}
    </span>
  );
})`
  letter-spacing: 0.3px;
  margin: 0 0 4px 0;
  display: inline-block;
  color: var(--gray-dark);
`;

const FormPhoneInput = styled(
  ({ className, onChange, value, valid, ...rest }: ClassNameProps & DefaultInputComponentProps) => {
    return (
      <div className={`${className}`}>
        <div id="phone-wrapper">
          <div id="phone-input">
            <PhoneInput {...rest} onChange={onChange} value={value} />
          </div>
          <div id="phone-validity">
            {valid ? <Icon icon={faCircleCheck} color="green" /> : <Icon icon={faCircleXmark} color="red" />}
          </div>
        </div>
      </div>
    );
  }
)`
  input {
    border: 0px;
  }

  input:focus {
    border: 0px;
    outline: 0px;
  }

  #phone-wrapper {
    display: flex;
  }

  #phone-validity {
    margin-left: auto;
  }
`;

const FormButtonWrap = styled(({ className, children }) => {
  return <div className={className}>{children}</div>;
})`
  text-align: right;
  margin-top: 12px;
  .btn {
    min-width: 75px;
    justify-content: center;
  }
`;

export default Form;
export {
  FormButtonWrap,
  FormCheck,
  FormControl,
  FormDateInput,
  FormDateInputWithTime,
  FormGroup,
  FormInfo,
  FormLabel,
  FormPhoneInput,
  FormSelect,
  FormText,
  FormTextInput,
  SelectStyles
};
