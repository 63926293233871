import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, ListGroup, Offcanvas, Row,InputGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import { deleteUserListFilter, fetchUserListFilter, saveUserListFilter, UserListFilter } from '../../rest/userFilters';
import { useUserInfo } from '../../utils/auth';
import { useFormState } from '../../utils/form';
import { showError, showSuccess } from '../../utils/message';
import A from '../A';
import Button from '../Button';
import Form, { FormControl, FormGroup, FormLabel } from '../Form';
import Icon from '../Icon';
import { ModalProps } from '../Modal';
import ScrollPane from '../ScrollPane';

interface UserFilterModalProps extends ModalProps, ClassNameProps {
  type: string;
}

const UserFilterModal: React.FC<UserFilterModalProps> = styled(({ open, type, className, handleClose }) => {
  const location = useLocation();
  const userInfo = useUserInfo();

  const [data, setData] = useState<UserListFilter[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(true);

  const [state, setState] = useState<{ filterName: string }>({ filterName: '' });

  const bind = useFormState(state, setState);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (needsRefresh) {
      fetchUserListFilter(userInfo?.sub!, (data: UserListFilter[]) => {
        setData(
          data
            ?.filter((element) => element.filter_type === type)
            .sort((filter1, filter2) => (filter1.filter_name > filter2.filter_name ? 1 : -1))
        );
      });
      setLoading(false);
      setNeedsRefresh(false);
    }
  }, [needsRefresh, userInfo, type]);

  const handleDeleteFilter = (id) => {
    deleteUserListFilter(
      userInfo?.sub!,
      id,
      () => {
        showSuccess('Filter deleted');
        setNeedsRefresh(true);
      },
      (error) => {
        console.log(error);
        showError('Unable to delete filter');
      }
    );
  };

  const handleSaveFilter = (event) => {
    event.preventDefault();
    if (data?.some((element) => element.filter_name === state.filterName)) {
      setError('Name is already in use.');
      return;
    }
    setError('');
    saveUserListFilter(
      userInfo?.sub!,
      type,
      state.filterName,
      `${location.search}`,
      () => {
        showSuccess('Filter saved');
        setState({ filterName: '' });
        setNeedsRefresh(true);
      },
      (error) => {
        console.error(error);
        showError('Unable to save filter');
      }
    );
  };

  return !open ? null : (
    <Offcanvas className={className} onHide={handleClose} show={open} placement="end">
      <Offcanvas.Header closeButton>
        <h4>Saved Filters</h4>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSaveFilter}>
          <Row>
            <Col>
              <FormGroup>

                <FormLabel>Save Current Filter as:</FormLabel>
                {!isNil(error) && <div className="error">{error}</div>}
                <InputGroup>
                  <FormControl
                    {...bind('filterName')()}
                    style={{ maxWidth: 'calc(100% - 60px)', display: 'inline-block' }}
                    placeholder="Filter name..."
                    autoComplete="off"
                    type="text"
                    maxLength="50"
                    name="filterName"
                  />
                  <Button
                    title={'Save filter using the supplied filter name'}
                    variant={'primary'}
                    onClick={handleSaveFilter}
                    disabled={state.filterName === ''}
                  >
                    <Icon icon={faSave} />
                  </Button>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <ScrollPane height="800px">
          {loading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <ListGroup>
              {data?.map((filter: any) => {
                return (
                  <ListGroup.Item key={filter.id}>
                    <A onClick={handleClose} to={`${location.pathname}${filter.filter}&r=false`}>
                      {filter.filter_name}
                    </A>
                    <Button title="Delete saved filter" onClick={() => handleDeleteFilter(filter.id)} variant="white">
                      <Icon icon={faTrash} />
                    </Button>
                  </ListGroup.Item>
                );
              })}
              {data?.length < 1 ? (
                <ListGroup.Item key={1} className={'no-saved'}>
                  You have no saved filters
                </ListGroup.Item>
              ) : null}
            </ListGroup>
          )}
        </ScrollPane>
      </Offcanvas.Body>
    </Offcanvas>
  );
})`
  .modal-dialog {
    right: 5px;
    margin-right: 10px;
    top: -165px;
  }
  .btn.btn-primary {
    float: right;
  }
  .no-saved {
    color: var(--primary);
    text-align: center;
  }
  .list-group-item {
    height: 35px;
    .btn {
      display: none;
    }
    &:hover {
      .btn {
        padding: 0;
        display: inline-block;
        float: right;
        color: var(--danger);
      }
    }
  }
  .error {
    color: var(--danger);
    padding: 4px 0;
  }
`;

export default UserFilterModal;
